<template>
  <div class="APIsetting">
    <div class="editContent">
      <!-- <p>基础信息</p> -->
      <div class="basicInfo">

        <el-form :model="ruleForm" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label="启用API接口">
            <el-switch v-model="ruleForm.startApi">
            </el-switch>
            <!-- <el-input v-model="ruleForm.name" size="small"></el-input> -->
          </el-form-item>
          <el-form-item label="应用ID">
            <el-input v-model="ruleForm.siteId" size="small"></el-input>
          </el-form-item>
          <el-form-item label="签名密钥">
            <el-input v-model="ruleForm.value" size="small"></el-input>
          </el-form-item>

        </el-form>
      </div>
      <p>其他</p>
      <div class="updatePassword">

        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="200px" class="demo-ruleForm">

          <el-form-item label="模块数据的 读取API 无需签名">
            <el-switch v-model="ruleForm.modelInfo">
            </el-switch>
            <br />
            <span class="label">注意：开启后，其他程序可以正常读取文章模块等模块信息</span>
          </el-form-item>
          <el-form-item label="用户信息 读取API 无需签名">
            <el-switch v-model="ruleForm.userInfo">
            </el-switch>
            <br />
            <span class="label">注意：开启后，其他程序可以读取用户信息（非密码等敏感信息），请谨慎开启。</span>
          </el-form-item>
          <el-form-item label="配置信息 读取API 无需签名">
            <el-switch v-model="ruleForm.settingInfo">
            </el-switch>
            <br />
            <span class="label">注意：开启后，其他程序可以读取系统配置信息，请谨慎开启。</span>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')" size="small">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { updateAPISettingsAPI, APISettingsAPI } from '@/api/settings/api'
export default {
  data() {

    return {
      ruleForm: {
        value: null,
        setId: null,
        startApi: false,
        modelInfo: false,
        settingInfo: false,
        userInfo: false
      },

    };
  },
  methods: {
    // 提交修改
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {

          const res = await updateAPISettingsAPI(JSON.stringify(this.ruleForm));
          console.log(res);
          if (res.code === 200) {
            this.$message({
              showClose: true,
              message: '操作成功！',
              type: 'success'
            });

            this.getDetail();
          } else {
            this.$message({
              showClose: true,
              message: '操作失败！' + res.msg,
              type: 'error'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 获取详情
    async getDetail() {
      const res = await APISettingsAPI();
      if (res.code === 200) {
        this.ruleForm = res.data;
      } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '详情获取失败!'
                });
            }

    }
  },
  created() {
    this.getDetail();
  }
}
</script>

<style lang="scss" scoped>
.APIsetting {
  width: 100%;
  box-sizing: border-box;

  .editContent {
    border-radius: 5px;
    width: 70%;
    margin: 0 auto;
    // border: 1px solid #eee;
    box-shadow: 0px 0px 4px 1px #ccc;
    padding: 20px;

    p {
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
    }

    .label {
      font-size: 12px;
    }

    .basicInfo,
    .updatePassword {
      margin: auto;
      width: 60%;
      // border-bottom: 1px solid #ccc;
    }
  }
}</style>